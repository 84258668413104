<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>合约估价</el-breadcrumb-item>
    
      </el-breadcrumb>
    </div>

    

     <!-- <div class="data_item">
      <el-radio-group v-model="tabActive" @change="tabChange">
        <el-radio-button :label="item.id" v-for="(item,index) in tabsList.data" :key="index" >{{item.name}}</el-radio-button>
     
       </el-radio-group>
    </div> -->


     <div v-if="tabActive==2">

       <div class="flex_between">
        <div class="operating_title">价格场景列表</div>

         <div class="flex_start">
        <el-button class="data_btns flex_center" >
          <i class="icon iconfont icon-xinzeng"></i>
          <span>新建</span>
        </el-button>

        <el-button class="data_btns flex_center" >
          <i class="icon iconfont icon-shanchu"></i>
          <span>批量删除</span>
        </el-button>

         </div>


        </div> 

           <el-table
          :data="priceList.data"
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column prop="name" label="名称" align="center" />
          <el-table-column prop="priceType" label="价格类型" align="center" />
          <el-table-column prop="dataType" label="区分工作日类型" align="center" />
          <el-table-column prop="historyDay" label="参考历史日" align="center" />
          <el-table-column prop="detail" label="操作" align="center">
            <el-button type="primary" @click="importFn()" link>详情</el-button>
          </el-table-column>
        </el-table>

           <div class="flex_end">
          <el-pagination
            :page-size="20"
            :pager-count="6"
            layout="prev, pager, next"
            :total="1000"
            class="pages"
          />
        </div>

          <div class="flex_between">
        <div class="operating_title">价格带曲线详情</div>
        </div> 

        <div class="stock_item">
          <div class="add_container">

              <div class="prop_select">
               <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
             
           
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item,index) in priceTit.title1"
                :key="index"
              ></el-tab-pane>
            </el-tabs>

   
             </div>


           <div class="prop_select">
               <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
             
           
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item,index) in priceTit.title2"
                :key="index"
              ></el-tab-pane>
            </el-tabs>

   
             </div>
      

          </div>
        </div>



     </div>

      <div v-if="tabActive==1">

       <div class="flex_between">
        <div class="operating_title">测算合约列表</div>

         <div class="flex_start">
        <el-button class="data_btns flex_center" @click="importFn">
          <i class="icon iconfont icon-xinzeng"></i>
          <span>新建</span>
        </el-button>

        <el-button class="data_btns flex_center" @click="importFn">
          <i class="icon iconfont icon-daoru"></i>
          <span>导入</span>
        </el-button>

        <el-button class="data_btns flex_center" @click="importFn">
          <i class="icon iconfont icon-shanchu"></i>
          <span>批量删除</span>
        </el-button>

         </div>


        </div> 

        <el-table
          :data="forecastList.data"
          :header-cell-style="{
         background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          
           
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="buyer" label="名称" align="center" />
          <el-table-column prop="seller" label="标的时间" align="center" />
          <el-table-column prop="begin" label="成交方式" align="center" />
          <el-table-column prop="end" label="合约电量" align="center" />
          <el-table-column prop="power" label="合约均价" align="center" />
          <el-table-column prop="price" label="曲线分解方式" align="center" />
          <el-table-column prop="detail" label="操作" align="center">
            <el-button type="primary" @click="importFn()" link>详情</el-button>
          </el-table-column>
        </el-table>

        <!-- <div class="flex_end">
          <el-pagination
            :page-size="20"
            :pager-count="6"
            layout="prev, pager, next"
            :total="1000"
            class="pages"
          />
        </div> -->



    <template v-if="dialogImport">
      <div class="flex_between">
        <div class="operating_title">合约曲线详情</div>

        </div> 

         <div >
        <div class="stock_item">
          <div class="add_container">

              <div class="prop_select">
               <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
             
           
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item, index) in paneList.data"
                :key="index"
              ></el-tab-pane>
            </el-tabs>

            <div >
                单位：价格(厘/kWh) 电量(万kwh)
            </div>
             </div>

                  <el-table
          :data="detailList.data"
          :header-cell-style="{
         background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="details_table"
        >
     
        <template v-for="(item,index) in detailTit.data" :key="index">
          <el-table-column :fixed="index<3?true:false"  :label="item" align="center" :width="index==0?150:''">
            <template #default="scope">
                {{ scope.row[item] }}
            </template>
          </el-table-column>

         </template>

   
        </el-table>

          </div>
        </div>
        </div>
        <!-- <div class="flex_end">
          <el-pagination
            :page-size="20"
            :pager-count="6"
            layout="prev, pager, next"
            :total="1000"
            class="pages"
          />
        </div> -->



    </template>

      </div>
    <div v-if="tabActive==3">

       <div class="flex_between">
        <div class="operating_title">“测算合约+价格场景”组合</div>

         <div class="flex_start">
        <el-button class="data_btns flex_center" >
          <i class="icon iconfont icon-xinzeng"></i>
          <span>新建</span>
        </el-button>

        <el-button class="data_btns flex_center" @click="calc=true">
          <i class="icon iconfont icon-daoru"></i>
          <span>计算</span>
        </el-button>

        <el-button class="data_btns flex_center" >
          <i class="icon iconfont icon-shanchu"></i>
          <span>清空</span>
        </el-button>

         </div>


        </div> 

           <el-table
          :data="calculation.data"
          :header-cell-style="{
         background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          
           
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="buyer" label="组合名称" align="center" />
          <el-table-column prop="seller" label="测算合约" align="center" />
          <el-table-column prop="begin" label="成交方式" align="center" />
          <el-table-column prop="end" label="合约均价" align="center" />
          <el-table-column prop="detail" label="操作" align="center">
            <el-button type="primary" @click="importFn()" link>详情</el-button>
          </el-table-column>
        </el-table>


        <template v-if="calc">
         <div class="flex_between">
          <div class="operating_title">输出计算结果</div>

        </div> 
          <el-empty description="暂无结果" />
        </template>

    </div>


  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "evaluation",

  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dialogImport = ref(false);
    const importFn = () => {
      dialogImport.value = true;
      console.log(dialogImport.value);
    };
    let detailList=reactive({
        data:[]
    })
    let detailTit=reactive({
        data:[
            '合约日期','类型','汇总'
          
        ]
    })
    for(let i=0;i<24;i++){
        detailTit.data.push(i+'时')
    } 

    let forecastList = reactive({
      data: [
        {buyer:'惠州市钰塑精密制造有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2022-05',end:'120',price:'年分月',power:'1.2',begin:'线上',status:'合同变更',log:'--'},
        {buyer:'惠州市康惠顺实业有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2021-05',end:'130',price:'月分日',power:'1.2',begin:'线上',status:'合同变更',log:'--'},
        {buyer:'惠州市勤兴藤器有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2022-03',end:'210',price:'月分日',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州明德影像科技有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2022-04',end:'160',price:'月分日',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'平远冠华模具配件有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'120',price:'日分时',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市永涛塑胶科技有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'250',price:'年分月',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'佛山市伟利赢科技有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'220',price:'年分月',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市恒邦物业管理有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'420',price:'日分时',power:'1.2',begin:'线上',status:'新建合同',log:'--'},

        {buyer:'江门市吉谷物业管理有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'120',price:'日分时',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'开平市赤牛水泥制品有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'360',price:'日分时',power:'1.2',begin:'线上',status:'新建合同',log:'--'},
        {buyer:'广东甲宝输送机械股份有限公司',seller:'广东蚂蚁金谷能源有限公司',seller:'2023-06',end:'120',price:'月分日',power:'1.2',begin:'线上',status:'新建合同',log:'--'},

    ],
    });
    // for (let i = 0; i < 4; i++) {
    //   forecastList.data.push({
    //     id: i + 1,
    //     buyer: "企业名字",
    //     seller: "企业名字",
    //     begin: "2022",
    //     end: "2022",
    //     power: "月度分解曲线",
    //     price: "蚂蚁管理员",
    //     average: "2022.08.09   11:00PM",
    //     type: "市场价",
    //     status: "状态",
    //     log: "蚂蚁管理员",
    //   });
    //   let cuurent={}
     
    //   for(let t=0;t<detailTit.data.length;t++){
    //     if(t==0){
    //      cuurent[detailTit.data[t]]='2022.08.10'
    //     }else if(t==1){
    //      cuurent[detailTit.data[t]]='--'
    //     }else if(t==2){
    //      cuurent[detailTit.data[t]]=Number((Math.random()*5).toFixed(2))+'%'
    //     }else{
    //    cuurent[detailTit.data[t]]=Number(Math.random().toFixed(2))+'%'
    //     }
       
    //   }
    //   detailList.data.push(cuurent)

    // }


    let tabsList = reactive({
      data: [
        { id: 1, name: "测算合约录入" },
        { id: 2, name: "价格场景设置"},
        { id: 3, name: "合约估价与对比分析" },

      ],
    });
    let tabActive=ref(1)

   let paneList=reactive({data:
    [
        {id:1,name:'合约曲线'},
        {id:2,name:'电量分布'},
        {id:3,name:'量价明细'},

    ]
     })

     let calculation=reactive({data:[]})


    let paneActive=ref(1)

    let calc=ref(false)

    let priceList=reactive({
        data:[]

    })

    let current={
        name:'2022-MD-1',
        priceType:'2022',
        dataType:'月度分解曲线',
        historyDay:'2022.08.09   11:00PM'

    }

    for(let i=0;i<3;i++){
    priceList.data.push(current)

    // forecastList.data.push({
    //     id: i + 1,
    //     buyer: "企业名字",
    //     seller: "企业名字",
    //     begin: "2022",
    //     end: "2022",
    //     power: "月度分解曲线",
    //     price: "蚂蚁管理员",
    //     average: "2022.08.09   11:00PM",
    //     type: "市场价",
    //     status: "状态",
    //     log: "蚂蚁管理员",
    //   });

    }

    let priceTit=reactive({
        title1:[
        {name:'价格带曲线',id:1},
        {name:'价格分布情况',id:2},
        ],
        title2:[
        {name:'价格统计',id:1},
        {name:'价格明细',id:2},
        ], 
    })
    

     

    const tabChange=()=>{
     calc.value=false
    dialogImport.value = false;
     }

    onMounted(() => {
      nextTick(() => {});
    });
    return {
      forecastList,
      dialogImport,
      importFn,
      tabsList,
      tabActive,
      paneList,
      paneActive,
      detailTit,
      detailList,
      calculation,
      calc,
      priceList,
      tabChange,
      priceTit
    };
  },
};
</script>

<style lang="scss" scoped>

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table,.details_table{
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
  &:deep(.el-table__header-wrapper){
    padding: 0 10px;
    background: #022C63
  }
}

.details_table{
     &:deep(.el-scrollbar) {
    padding: 0 10px 0;

  } 
}
.data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}

.pages {
  margin-top: 25px;
  padding: 4px 0;
  background-color: #1f2935;
  --el-pagination-bg-color: #1f2935;
  border-radius: 6px;
  color: #ffffff;
  &:deep(button:disabled) {
    background-color: #1f2935;
  }
  &:deep(.el-pager li.is-active) {
    color: #ffffff;
    background: #0081ff;
    border-radius: 8px;
    margin: 0;
  }
  &:deep(.btn-prev) {
    color: #ffffff;
  }
  &:deep(.btn-next) {
    color: #ffffff;
  }
  &:deep(.btn-quickprev) {
    color: #ffffff;
  }
  &:deep(.btn-quicknext) {
    color: #ffffff;
  }
}

.stock_item {
  width: 100%;
  .operation {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;

    &:deep(.el-date-editor.el-input__wrapper) {
      background: #1f2935;
      box-shadow: none;
    }

    &:deep(.select_day) {
      width: 120px;
      margin: 0 32px;
    }
    &:deep(.el-select .el-input__wrapper) {
      background: #1f2935;
      box-shadow: none;
    }
    &:deep(.day_type .el-select__tags .el-tag--info) {
      background: #e0eaff;
    }
  }
}
.add_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  background: #0b183c;
  border-radius: 8px;
  padding: 20px 0;
}

.operating_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}
  .forecast_tabs {
  &:deep(.el-tabs__item) {
    color: #ffffff;
  }
  &:deep(.el-tabs__active-bar) {
    background: #36d1ff;
  }

  &:deep(.el-tabs__nav-wrap::after) {
    background: #8291a9;

  }
}
.prop_select{
    width: 100%;
    padding: 0 23px;
    margin: 25px 0;
    margin-top: 10px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8291A9;
}
</style>
